<template>
  <div class="col-md-12" style="padding: 10px;">
    <div class="card card-map" id="card-content">
      <div class="card-header">
        <div class="row">
          <div class="col-md-1">
            <button class="btn" @click="backClick()">Volver</button>
          </div>
        <h4 class="card-title"> <strong>{{detalleReporte.patente}}</strong> -
        <!-- <i class="nc-icon nc-calendar-60 "></i> -->
         Desde  {{iniDateString}} hasta  {{toDateString}}</h4>
        </div>
      </div>
      <div class="card-body">
        <div id="heatMapId">
        <p-progress :value="progressValue" type="info"></p-progress>
          <GmapMap
            :center="center"
            :options="mapOptions"
            style="width: 100%; height: 100%"
            ref="heatMapRef"
          ></GmapMap>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Slider } from "element-ui";
import { mapState } from "vuex";
import GmapMap from "vue2-google-maps/dist/components/map";
import { gmapApi } from "vue2-google-maps";
// import PProgress from 'src/components/UIComponents/Progress.vue'

/// #if process.env.VUE_APP_DEVMODE === 'standalone'
import { PProgress, Card, StatsCard } from "../../../../ingeo-mf-styleguide/src/UIComponents";
import reporteService from "../../../../ingeo-mf-styleguide/src/services/reporte"
/// #else
import { PProgress, Card, StatsCard } from "@ingeo-mf/styleguide";
import { reporteService } from "@ingeo-mf/styleguide";
/// #endif

export default {
  components: {
    [Slider.name]: Slider,
    Card,
    StatsCard,
    GmapMap,
    PProgress
  },
  computed: {
    ...mapState(["detalleReporte", "historicoMapa"]),
    google: gmapApi
  },
  data() {
    return {
      center: { lat: -33.748817, lng: -60.985428 },
      mapOptions: {
        zoom: 8,
        scrollwheel: true,
        mapTypeId: "terrain",
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: true,
        rotateControl: true,
        fullscreenControl: true,
        disableDefaultUi: false,
        mapTypeControlOptions: {
          style: "",
          position: ""
        }
      },
      heatMapObj: {},
      googleMapsInitialized: false,
      heatDataPoints: [],
      heatMapBounds: [],
      heatmap: {},
      progressValue: 0,
      iniDateString:'',
      toDateString: ''
    };
  },
  methods: {
    mapInitialize() {
      this.mapOptions.mapTypeControlOptions.style = this.google.maps.MapTypeControlStyle.HORIZONTAL_BAR;
      this.mapOptions.mapTypeControlOptions.position = this.google.maps.ControlPosition.TOP_RIGHT;
    },
    handleResize() {
      // let navbarHeight = document.getElementById("navbar").offsetHeight;
      let navbarHeight = 50;
      let windowHeight = window.innerHeight;
      let contentHeight = windowHeight - navbarHeight;
      let mapHeight = contentHeight - 130;
      document.getElementById("heatMapId").style.height = mapHeight + "px";
    },
    backClick() {
      this.$router.go(-1);
    },
    async getHeatPoints() {
      let iniDate = new Date(this.detalleReporte.fechaDesde);
      let toDate = new Date(this.detalleReporte.fechaHasta);

      this.iniDateString = iniDate.toISOString().substring(8, 10)+iniDate.toISOString().substring(4, 8)+iniDate.toISOString().substring(0, 4);
      this.toDateString = toDate.toISOString().substring(8, 10)+toDate.toISOString().substring(4, 8)+toDate.toISOString().substring(0, 4);
      var datesToQuery = [];
      this.heatMapBounds = new this.google.maps.LatLngBounds();

      const waitFor = ms => new Promise(r => setTimeout(r, ms));
      var daysToQuery=0
      for (var d = iniDate; d <= toDate; d.setDate(d.getDate() + 1)) {
        datesToQuery.push(d.toISOString().substring(0, 10));
        daysToQuery++
      }
      var currentProgress = 0
      for (const dateRep of datesToQuery) {
        let dayRep = await reporteService.historicoMapa(
          this.detalleReporte.patente,
          dateRep
        ).then(res => {
          return res.data;
        });

        if (dayRep){
            dayRep.forEach(item => {
                let LatLngPoint = new this.google.maps.LatLng(item.lat, item.lon);
                this.heatDataPoints.push(LatLngPoint);
                this.heatMapBounds.extend(LatLngPoint);
            })
        }

        await waitFor(500);

        currentProgress += 100/daysToQuery
        this.progressValue = (currentProgress>100)?100:currentProgress;

      }

      this.heatmap = new this.google.maps.visualization.HeatmapLayer({
        data: this.heatDataPoints
      });
      this.changeGradient()
      this.changeRadius()
      this.heatmap.setMap(this.heatMapObj);
      this.heatMapObj.fitBounds(this.heatMapBounds)

    },
    changeGradient() {
      var gradient = [
        "rgba(0, 255, 255, 0)",
        "rgba(0, 255, 255, 1)",
        "rgba(0, 191, 255, 1)",
        "rgba(0, 127, 255, 1)",
        "rgba(0, 63, 255, 1)",
        "rgba(0, 0, 255, 1)",
        "rgba(0, 0, 223, 1)",
        "rgba(0, 0, 191, 1)",
        "rgba(0, 0, 159, 1)",
        "rgba(0, 0, 127, 1)",
        "rgba(63, 0, 91, 1)",
        "rgba(127, 0, 63, 1)",
        "rgba(191, 0, 31, 1)",
        "rgba(255, 0, 0, 1)"
      ];
      this.heatmap.set(
        "gradient",
        this.heatmap.get("gradient") ? null : gradient
      );
    },
    changeRadius() {
      this.heatmap.set("radius", this.heatmap.get("radius") ? null : 20);
    },
    mapLoadError(error) {
      alert("No se pudo inicializar el mapa:", error);
    }
  },

  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.$refs.heatMapRef.$mapPromise
      .then(mapObj => {
        this.heatMapObj = mapObj;
        this.mapInitialize();
        this.getHeatPoints();
      })
      .catch(error => this.mapLoadError(error));
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  }
};
</script>
<style scoped>
/* .row {
  margin: 0px;
} */
.header-row {
  height: 100px;

}
.row-fluid {
  content: "";
  clear: both;
  display: table;
  line-height: 130%;
}

.chartLabel .stats {
  color: black;
}

.chartLabel {
  position: relative;
  width: 20%;
  padding: 20px;
  /* padding-bottom: 10px; */
  float: right;
  /* margin: -5px; */
  /* border-right: 2px solid;
   border-color: aliceblue; */
  margin-top: 0;
  padding-top: 0;
}

/* .chartArea {
  position: fixed;
  bottom: 0;
} */

#card-content {
  color: #d6d2c8;
  min-width: 240px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 1);
}

.card{
  color: gray!important;
  font-size: 1.1em;
  min-width: 240px;
  box-shadow: 0px 6px 10px 1px rgba(0, 0, 0, 0.5);
}
.card-title {

    float: right;
    position: absolute;
    right: 40px;
    text-align: right;
}

</style>
