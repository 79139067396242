import { setPublicPath } from "systemjs-webpack-interop";
const path = require("path");
const devMode = process.env.VUE_APP_DEVMODE;

if (devMode === "serve") {
  setPublicPath("http://localhost:8504/js");
}

if (devMode === "build") {
  setPublicPath(path.resolve(__dirname, "/mod/map/js"));
}
