const names = {
  ID: 'log_rep_id',
  REP_VEH: 'veh_id',
  REP_DATE: 'log_rep_date',
  REP_DAY: 'log_week_day',
  // REP_KM: 'log_rep_distance',
  REP_KM: 'log_distance_km',
  REP_TIME_ON: 'log_rep_trip_time',
  REP_TIME_OFF: 'log_rep_stop_time',
  // REP_MAX_VEL: 'log_rep_max_speed',
  REP_MAX_VEL: 'max_vel_string'
}

const resourceDisplayName = {
  [names.ID]: 'Id',
  [names.REP_VEH]: 'Vehículo',
  [names.REP_DATE]: 'Fecha',
  [names.REP_DAY]: 'Día',
  [names.REP_KM]: 'Distancia',
  [names.REP_TIME_ON]: 'En Viaje',
  [names.REP_TIME_OFF]: 'Parado',
  [names.REP_MAX_VEL]: 'Vel. Máx',
  [names.REP_MAP_ICON]: 'Acciones'
}

const fields = [
  // {
  //   key: 'index',
  //   label: '#'
  // },
  {
    key: names.REP_VEH,
    label: resourceDisplayName[names.REP_VEH],
    sortable: true
  },
  {
    key: names.REP_DATE,
    label: resourceDisplayName[names.REP_DATE],
    sortable: true
  },
  {
    key: names.REP_DAY,
    label: resourceDisplayName[names.REP_DAY],
    sortable: true
  },
  {
    // key: names.REP_KM,
    key: names.REP_KM,
    label: resourceDisplayName[names.REP_KM],
    sortable: true
  },
  {
    key: names.REP_TIME_ON,
    label: resourceDisplayName[names.REP_TIME_ON],
    sortable: true
  },
  {
    key: names.REP_TIME_OFF,
    label: resourceDisplayName[names.REP_TIME_OFF],
    sortable: true
  },
  {
    key: names.REP_MAX_VEL,
    label: resourceDisplayName[names.REP_MAX_VEL],
    sortable: true
  },
  {
    key: 'actions',
    label: 'Acciones',
    thStyle: {width: '135px !important'}
  },
  // {
  //   key: 'show_details',
  //   label: '',
  //   thStyle: {width: '30px !important'}
  // }
];

export default {
  names,
  fields,
  methods: {
    /**
     * Resource Normalized name
     * @param regKey {String}
     * @returns {String}
     */
    resourceDisplayName (regKey) {
      return resourceDisplayName[regKey]
    }
  }
}
