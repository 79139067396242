<template>
  <record>

    <template v-slot:Title>
      <div class="title"><h4> {{titleView}} </h4></div>
    </template>

    <template v-slot:RecordLayout>

      <template v-if="!isViewForm">
        <b-card>
          <b-button-toolbar class="mb-4" justify aria-label="Toolbar with button groups and input groups">
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text class="d-none d-md-block">
                  <b-icon icon="search"></b-icon>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-input
                v-model="filter"
                type="search"
                id="filterInput"
                placeholder="Filtrar. Ingrese texto..."
              ></b-input>
            </b-input-group>

            <b-button-group>
              <b-dropdown  class="ml-1" right toggle-class="text-decoration-none" no-caret>
                <template v-slot:button-content>
                  <span class="d-none d-md-block"><b-icon icon="download" class="mr-1"></b-icon>Export</span>
                  <span class="d-md-none"><b-icon icon="download"></b-icon></span>
                </template>
                <b-dropdown-item>
                  <download-excel
                    type = "xls"
                    worksheet = "Vehiculos"
                    :name = "fullFileName"
                    :data = "items">
                    <span>Basico</span>
                  </download-excel>
                </b-dropdown-item>
                <b-dropdown-item @click="showExport=true">Completo</b-dropdown-item>
              </b-dropdown>

              <!-- <b-button class="ml-1"> -->
                <!-- TODO agregar vista con control de EXPORT -->
                <!-- <download-excel
                  type = "xls"
                  worksheet = "Vehiculos"
                  :name = "fullFileName"
                  :data = "items">
                  <div class="d-none d-md-block"><b-icon icon="download" class="mr-1"></b-icon>Export</div>
                  <div class="d-md-none"><b-icon icon="download"></b-icon></div>
                </download-excel>
              </b-button> -->

              <b-button class="ml-1" @click="onChangeView()">
                <div class="d-none d-md-block"><b-icon icon="grid-fill" class="mr-1"></b-icon>Registros</div>
                <div class="d-md-none"><b-icon icon="grid-fill"></b-icon></div>
              </b-button>
            </b-button-group>

          </b-button-toolbar>

          <ModalExport :show="showExport" @onClose="showExport=false"> </ModalExport>

          <b-table
            striped
            hover
            stacked="sm"
            :head-variant="headVariant"
            :items="items"
            :fields="fields"
            :borderless="borderless"
            ref="selectableTable"
            selectable
            select-mode="single"
            @row-selected="onRowSelected"
            sort-icon-left
            :filter="filter"
          >
            <template v-slot:thead-top="data">
              <b-tr>
                  <b-th colspan="3" style="font-size: 14px; color: #606266; font-weight: bolder;"><span>TOTALES</span></b-th>
                  <!-- <b-th colspan="2"><span class="sr-only"></span></b-th> -->
                  <b-th variant=""   style="font-size: 14px; color: #606266; font-weight: bolder;">Odometro</b-th>
                  <b-th variant=""   style="font-size: 14px; color: #606266; font-weight: bolder;">T. Viaje</b-th>
                  <b-th variant=""   style="font-size: 14px; color: #606266; font-weight: bolder;">T. Detenido</b-th>
                  <b-th variant=""   style="font-size: 14px; color: #606266; font-weight: bolder;">Vel. Max.</b-th>
                  <b-th variant=""> </b-th>
                </b-tr>
                <b-tr >
                  <b-th colspan="1" style="background:#ffffff; font-size: 14px; color: #606266; font-weight: bolder;"><span></span></b-th>
                  <b-th colspan="2" style="background:#ffffff; font-size: 14px; color: #606266; font-weight: bolder;"><span class="sr-only"></span></b-th>
                  <b-th variant=""  style="background:#ffffff; font-size: 14px;  color: #606266; font-weight: bolder;"> {{(total.distance/1000).toFixed(1)}} Km</b-th>
                  <b-th variant=""  style="background:#ffffff; font-size: 14px;  color: #606266; font-weight: bolder;"> {{formatSecondsToTime(total.travel)}}</b-th>
                  <b-th variant=""  style="background:#ffffff; font-size: 14px;  color: #606266; font-weight: bolder;"> {{formatSecondsToTime(total.stopped)}}</b-th>
                  <b-th variant=""  style="background:#ffffff; font-size: 14px;  color: #606266; font-weight: bolder;"> {{total.vmax}} Km/h</b-th>
                  <b-th variant=""  style="background:#ffffff; font-size: 14px;  color: #606266; font-weight: bolder;border-right:  5px;"> </b-th>

                </b-tr>
                <b-tr >
                  <b-th style="background:#ffffff;"></b-th>
                </b-tr>
            </template>

            <template v-slot:cell(actions)="row">
              <a @click="row.toggleDetails">
                <template >
                  <b-button variant="white" size="sm" v-b-tooltip.hover title="Mapa">
                    <b-icon v-on:click="goToTrackDay(row)" icon="map"></b-icon>
                  </b-button>
                </template>
                <!-- <template >
                  <b-button variant="white" size="sm" v-b-tooltip.hover title="TimeLine">
                    <b-icon  @click="goToTimeLineDay(row)" icon="card-list"></b-icon>
                  </b-button>
                </template> -->
                <template>
                  <b-button  variant="white" size="sm" v-b-tooltip.hover title="Descarga" >
                    <b-icon @click="downloadDetail(row)" icon="cloud-download"></b-icon>
                  </b-button>
                </template>
              </a>
            </template>
        </b-table>
        </b-card>
      </template>

      <template v-else>
        <b-card>
          <b-button-toolbar class="mb-4" justify key-nav>
            <b-button-group>
            </b-button-group>

            <b-button-group>
              <b-button class="mr-1 d-none d-md-block" disabled>
                {{ indexSelected + 1 }} / {{  items.length }}
              </b-button>
              <b-button class="mr-1" @click="onPrev()">
                <b-icon icon="chevron-left"></b-icon>
              </b-button>
              <b-button class="mr-1" @click="onNext()">
                <b-icon icon="chevron-right"></b-icon>
              </b-button>

              <b-button @click="onChangeView()">
                <div class="d-none d-md-block"><b-icon icon="list-ul" class="mr-1"></b-icon>Lista</div>
                <div class="d-md-none"><b-icon icon="list-ul"></b-icon></div>
              </b-button>
            </b-button-group>
          </b-button-toolbar>

          <!-- Detalle Dia x Dia -->
            <b-card-group deck  >
              <b-card :header=resourceDisplayName(names.REP_VEH) align="center">
                <b-card-text align="center"> {{ item[names.REP_VEH]}} </b-card-text>
              </b-card>
              <b-card :header=resourceDisplayName(names.REP_DATE) align="center">
                <b-card-text align="center"> {{ item[names.REP_DATE]}} </b-card-text>
              </b-card>
              <b-card :header=resourceDisplayName(names.REP_DAY) align="center">
                <b-card-text align="center"> {{ item[names.REP_DAY]}} </b-card-text>
              </b-card>
              <b-card :header=resourceDisplayName(names.REP_KM) align="center">
                <b-card-text align="center"> {{item[names.REP_KM]}} </b-card-text>
              </b-card>
            </b-card-group>

            <br>

            <b-card-group deck>
              <b-card :header=resourceDisplayName(names.REP_TIME_ON) align="center">
                <b-card-text align="center"> {{ item[names.REP_TIME_ON]}} </b-card-text>
              </b-card>
              <b-card :header=resourceDisplayName(names.REP_TIME_OFF) align="center">
                <b-card-text align="center"> {{ item[names.REP_TIME_OFF]}} </b-card-text>
              </b-card>
              <b-card :header=resourceDisplayName(names.REP_MAX_VEL) align="center">
                <b-card-text align="center"> {{ item[names.REP_MAX_VEL] }} </b-card-text>
              </b-card>
            </b-card-group>

        </b-card>

      </template>

    </template>
  </record>
</template>

<script>
  import Record from '@/components/Record/Index';

  import dataTable from "./data"
  import resources from '@/mixins/resourcesReport'
  import TimeLine from '../TimeLine/Index'


  import EventBus from "@/eventBus"

/// #if process.env.VUE_APP_DEVMODE === 'standalone'
import reporteService from "@/../../ingeo-mf-styleguide/src/services/reporte"
/// #else
import { reporteService } from "@ingeo-mf/styleguide";
/// #endif

  import ModalExport from './ModalExport'

  export default {
    name: 'Reports',
    components: {
      Record,
      ModalExport
    },
    mixins: [
      resources
    ],
    data() {
      return {
        titleModule: "Reportes",
        titleSubModule: "Registros",

        isViewForm: false,
        showExport:false,

        fileName: "Test",

        filter: null,
        headVariant: 'light',
        borderless: false,
        names: resources.names,
        fields: resources.fields,
        // items: dataTable.items,

        indexSelected: -1,
        selected: [],

        dateTo: "",
        dateFrom: "",
        items: [],

        v_max : [],
        total:
          {
            distance: 0,
            travel: 0,
            stopped: 0,
            vmax: ''
          },
        // vmax: 0,
        // distance: 0,
        // travel: 0,
        // stopped: 0,

        selectedVeh: 0
      }
    },

    computed: {
      titleView() {
        return `${this.titleModule} / ${this.titleSubModule}`
      },
      fullFileName () {
        let now = new Date();
        let nowDay = now.toISOString().slice(0,10).replace(/-/g,"");
        return `${this.fileName.trim().toLowerCase()}_${nowDay}.xls`
      },
      item() {
        if(this.indexSelected >= 0) {
          return this.items[this.indexSelected]
        }
        return item = []
      }
    },

    created() {
      this.items = this.$store.getters.getRoutesItems

      EventBus.$on('update-table', (item) => {
        this.items = []
        this.$store.commit('setRoutesResult', {
          items: []
        })
        this.total.distance = 0
        this.total.travel = 0
        this.total.stopped = 0
        this.total.vmax = ''

        this.dateFrom = this.$store.getters.getDateFrom
        this.dateTo = this.$store.getters.getDateTo
        this.selectedVeh = this.$store.getters.getSelectedVeh

        this.selectedVeh.forEach(vehicle => {
          reporteService.resumenRecorridos( vehicle.pat, this.dateFrom, this.dateTo)
          .then(res => {
            res.data.forEach(route => {
              route.log_week_day = this.showNameDay(route.log_rep_date)
              route.log_distance_km = (route.log_rep_distance/1000).toFixed(1) + " km"
              route.max_vel_string = (route.log_rep_max_speed) + " km/h"
              this.items.push(route)

              this.calculateTotal(route)
            })
          })
        })
        // console.log("🚀 ~ file: Index.vue ~ line 298 ~ EventBus.$on ~  this.items",  this.items)
        this.$store.commit('setRoutesResult', {
          items: this.items
        })
      })
    },

    mounted() {
      this.items.forEach(route => {
        this.calculateTotal(route)
      })
    },

    methods: {
      calculateTotal(route) {
        this.total.distance = parseInt(this.total.distance) + parseInt(route.log_rep_distance, 10)

        this.total.travel = this.total.travel + parseInt(this.formatTimeToSeconds(route.log_rep_trip_time))
        this.total.stopped = this.total.stopped + parseInt(this.formatTimeToSeconds(route.log_rep_stop_time))

        if (this.total.vmax < route.log_rep_max_speed  ) {
          this.v_max.push(route.log_rep_max_speed)
        }
        this.total.vmax = Math.max.apply(Math, this.v_max)
      },
      formatTimeToSeconds(fecha) {
        const a = fecha.split(':');
        const seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
        return seconds
      },
      formatSecondsToTime(seg) {
        if (seg >= 86400) {
          var d = Math.floor(seg / 86400);
          var h = Math.floor(seg % 86400 / 3600 )
        }
        else {
            var h = Math.floor(seg / 3600 )
        }

        if (h < 10) {
            h = "0"+ h
        }

        let m = Math.floor(seg % 3600 / 60);
        let s = Math.floor(seg % 3600 % 60);

        if (m < 10) {
            m = "0"+ m
        }
        if (s < 10) {
            s = "0"+ s
        }
        if (seg >= 86400) {
            var dhms = d + 'd ' + h + ':'+ m + ':' + s
            return dhms
        }

        let hms = h + ':'+ m + ':' + s

        return hms
      },
      formatDate(fecha) {
        const newdate = new Date(fecha)
        return newdate.toISOString().substring(0, 10);
      },
      goToTrackDay(row) {
        this.$store.commit('setStorymap' ,{
          fila: row.item
        })
        this.$store.commit("setDetalleReporte", {
          patente: row.veh_id,
          idReporte: row.log_rep_id,
          tableData: this.tableData,
          fechaDesde: this.dateFrom,
          fechaHasta: this.dateTo,
          vehiculos:  this.selectedVeh,
          tableData: this.items
        });
        // EventBus.$emit('add-items', row.index)
        this.$router.push({ name: 'TrackingDetail', params: { indexSelected : row.index  }} );
      },
      goToTimeLineDay(row) {
        // console.log("goToTimeLineDay -> row", row)
        // this.onRowSelected(row);
        this.$store.commit('setStorymap' ,{
          fila: row
        })
        this.$store.commit("setDetalleReporte", {
          patente: row.veh_id,
          idReporte: row.log_rep_id,
          tableData: this.tableData,
          fechaDesde: this.dateFrom,
          fechaHasta: this.dateTo,
          vehiculos:  this.selectedVeh ,
          tableData: this.items
        })
        // this.onRowSelected(row);
        this.$router.push({ name: 'TimeLineDetail', params: { indexSelected: row.index } });
      },
      downloadDetail(row) {
        console.log("Download", row);
        let urlxls =
          process.env.VUE_APP_API_REP_URL +
          "index.php?veh=" +
          row.item.veh_id +
          "&from=" +
          row.item.log_rep_date +
          // this.formatDate(this.dateFrom) +
          "&to=" +
          row.item.log_rep_date;
          // this.formatDate(this.dateTo);

        urlxls += "&trip=full";
        urlxls += "&event=yes";
        urlxls += "&xval=";
        urlxls += '$5INzGz|7LoUlj!)pvTO9Ur[Oz[0}';
        // urlxls += process.env.VUE_APP_INDEX_KEY;

        window.open(urlxls, "_blank");
        // this.$emit('change-view')
      },
      onRowSelected(item) {
        let index = -1
        if (item.length) {
          index = this.items.findIndex(i => i.veh_id === item[0].veh_id)
        }
        this.$emit('selected', index)
        this.selected = item
      },
      onChangeView() {
        // console.log('change to table/form >>>>')
        // TODO control de items lenght CERO
        this.isViewForm = !this.isViewForm
        if (!this.isViewForm) {
          this.indexSelected = -1
        }
        if (this.indexSelected === -1){
          this.indexSelected = 0
        } else {
          this.indexSelected = this.items.findIndex(i => i.veh_id === this.selected[0].veh_id)
        }
      },
      onPrev() {
        // TODO control de items lenght CERO
        if(this.indexSelected > 0) {
          this.indexSelected--
        } else {
          this.indexSelected = this.items.length - 1
        }
      },
      onNext() {
        // TODO control de items lenght CERO
        if(this.indexSelected < this.items.length - 1) {
          this.indexSelected ++
        } else {
          this.indexSelected = 0
        }
      },
      showNameDay(value){
        let days = ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'];
        let current_datetime = (new Date(value)).getDay();
        return days[current_datetime];
      },
    }
  }
  </script>
