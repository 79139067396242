<template>
  <record>

    <template v-slot:Title>
      <div class="title"><h4 id="title-template"> {{titleView}} </h4></div>
    </template>


    <template v-slot:RecordLayout id="record-template">

      <div v-show="!isViewForm">
        <b-card  class="mf-routes-tracking-card">
          <!-- <b-button-toolbar class="mb-2" justify aria-label="Toolbar with button groups and input groups">
            <b-button-group>

              <b-button class="ml-1">
                <download-excel
                  type = "xls"
                  worksheet = "Vehiculos"
                  :name = "fullFileName"
                  :data = "items">
                  <div class="d-none d-md-block"><b-icon icon="download" class="mr-1"></b-icon>Export</div>
                  <div class="d-md-none"><b-icon icon="download"></b-icon></div>
                </download-excel>
              </b-button>
              <b-button class="ml-1" @click="onChangeView()">
                <div class="d-none d-md-block"><b-icon icon="grid-fill" class="mr-1"></b-icon>Registros</div>
                <div class="d-md-none"><b-icon icon="grid-fill"></b-icon></div>
              </b-button>
            </b-button-group>
          </b-button-toolbar> -->
          <div class="row">
            <!-- TODO Inicio HEATMAP para el RANGO COMPLETO -->
            <div class="heatmap-container" id="heatMapId">
              <p-progress :value="progressValue" type="info"></p-progress>
              <GmapMap
                :center="center"
                :options="mapOptions"
                style="width: 100%; height: 100%"
                ref="heatMapRef"
              ></GmapMap>
            </div>
            <!-- fin HEATMAP rango completo -->
          </div>

        </b-card>
      </div>

      <div v-show="isViewForm">
        <b-card class="mf-routes-tracking-card">
          <b-button-toolbar class="mb-2" justify key-nav>
            <b-button-group class="slider-column">
              <slider style="width: 90%; margin-left: 30px;"></slider>
            </b-button-group>

            <b-button-group class="buttons-column">
              <b-button class="mr-1 d-none d-md-block" disabled>
                {{ indexSelected + 1 }} / {{  items.length }}
              </b-button>
              <b-button class="mr-1" @click="onPrev()">
                <b-icon icon="chevron-left"></b-icon>
              </b-button>
              <b-button class="mr-1" @click="onNext()">
                <b-icon icon="chevron-right"></b-icon>
              </b-button>

              <b-button @click="onChangeView()">
                <div class="d-none d-md-block"><b-icon icon="map" class="mr-1"></b-icon>Heatmap</div>
                <div class="d-md-none"><b-icon icon="list-ul"></b-icon></div>
              </b-button>
            </b-button-group>
          </b-button-toolbar>

          <div class="row">
            <div class="column">
              <b-card class="info-card" v-if="isViewForm"  align="center">
                <!-- <b-card-text align="center"> {{ items[indexSelected].veh_id}} </b-card-text> -->
                <p class="card-title">{{items[indexSelected].veh_id}}</p>
                <p class="card-category">Vehículo</p>
              </b-card>
               <b-card class="info-card" v-if="isViewForm" align="center">
                <!-- <b-card-text align="center"> {{ items[indexSelected].log_rep_date}} </b-card-text> -->
                <p class="card-title">{{convertDate(items[indexSelected].log_rep_date)}}</p>
                <p class="card-category">Fecha</p>
              </b-card>
               <b-card class="info-card" v-if="isViewForm" align="center">
                <!-- <b-card-text align="center"> {{ convertToKm(items[indexSelected].log_rep_distance)}} km </b-card-text> -->
                <p class="card-title">{{convertToKm(items[indexSelected].log_rep_distance)}} km</p>
                <p class="card-category">Distancia</p>
              </b-card>
            </div>

            <!-- TODO Inicio MAPA dia x dia -->
            <div class="map-column" id="historyMapId">
              <GmapMap
                :center="center"
                :options="mapOptions"
                style="width: 100%; height: 100%"
                ref="histMapRef"
              >
                <GmapPolyline
                  v-show="pointsInRange.length > 0"
                  :path="pointsInRange"
                  :options="pathOptions"
                  :editable="false"
                  ref="polyline"
                ></GmapPolyline>
              </GmapMap>
            </div>
            <!--  fin MAPA dia por dia -->

            <div class="column">
              <b-card class="info-card" v-if="isViewForm" align="center">
                <p class="card-title">{{items[indexSelected].log_rep_max_speed}} km/h</p>
                <p class="card-category">Vel. Máx</p>
              </b-card>
              <b-card class="info-card" v-if="isViewForm" align="center">
                <p class="card-title">{{items[indexSelected].log_rep_stop_time}}</p>
                <p class="card-category">Tiempo Parado</p>
              </b-card>
              <b-card class="info-card" v-if="isViewForm" align="center">
                <p class="card-title">{{items[indexSelected].log_rep_trip_time}}</p>
                <p class="card-category">Tiempo En Viaje</p>
              </b-card>
            </div>

          </div>
        </b-card>
      </div>

    </template>

  </record>
</template>

<script>
  import Record from '@/components/Record/Index';
  import Slider from '@/components/Slider/Index';

  import dataTable from "./data"
  import resources from '@/mixins/resourcesReport'

  import EventBus from "@/eventBus"

  import GmapMap from "vue2-google-maps/dist/components/map";
  import GmapPolyline from "vue2-google-maps/dist/components/polyline";
  import { gmapApi } from "vue2-google-maps";

  // #if process.env.VUE_APP_DEVMODE === 'standalone'
  import { PProgress } from "../../../../ingeo-mf-styleguide/src/UIComponents";
  import reporteService from "../../../../ingeo-mf-styleguide/src/services/reporte"

  /// #else
  // import { PProgress } from "@ingeo-mf/styleguide";
  // import { reporteService } from "@ingeo-mf/styleguide";

  export default {
    name: 'Reports',
    components: {
      Record,
      GmapMap,
      GmapPolyline,
      Slider,
      PProgress
    },
    mixins: [
      resources
    ],
    data() {
      return {
        titleModule: "Reportes",
        titleSubModule: "Recorridos",

        isViewForm: false,

        fileName: "Test",
        selected: [],

        filter: null,
        headVariant: 'light',
        borderless: false,
        names: resources.names,
        fields: resources.fields,
         //items: dataTable.items,
        items: [],
        indexSelected: 0,
        center: { lat: -32.957068, lng: -60.645484 },
        mapOptions: {
          zoom: 8,
          scrollwheel: true,
          mapTypeId: "terrain",
          zoomControl: true,
          mapTypeControl: true,
          scaleControl: true,
          streetViewControl: true,
          rotateControl: true,
          fullscreenControl: true,
          disableDefaultUi: false,
          mapTypeControlOptions: {
            style: "",
            position: ""
          }
        },
        historicMapObj: {},
        pointsInRange: [],
        pathOptions: {
          geodesic: false,
          strokeColor: "#005ec0",
          strokeOpacity: 0.8,
          strokeWeight: 6
        },
        slideBack: ['00:00', '23:59'],
        heatMapObj: {},
        googleMapsInitialized: false,
        heatDataPoints: [],
        heatMapBounds: [],
        heatmap: {},
        progressValue: 0,
        iniDateString:'',
        toDateString: '',
        heatmapDataReady: false,
      }
    },
    computed: {
      titleView() {
        return `${this.titleModule} / ${this.titleSubModule}`
      },
      fullFileName () {
        let now = new Date();
        let nowDay = now.toISOString().slice(0,10).replace(/-/g,"");
        return `${this.fileName.trim().toLowerCase()}_${nowDay}.xls`
      },
      item() {
        if(this.indexSelected >= 0) {
          return this.items[this.indexSelected]
        }
        return item = []
      },
      google: gmapApi
    },
    mounted() {
      this.items = this.$store.getters.getRoutesItems

      // console.log("mounted ->  this.items",  this.items)
      // console.log("this.$route.params.indexSelected ",this.$route.params.indexSelected )
      if (this.$route.params.indexSelected === undefined){
        this.isViewForm = !this.isViewForm
      }

      if( this.$route.params.indexSelected != -1) {
        this.isViewForm = !this.isViewForm
        this.indexSelected = this.$route.params.indexSelected
      }

      this.$refs.histMapRef.$mapPromise.then(mapObj => {
        this.historicMapObj = mapObj;
        this.mapInitialize();
        this.getHistoricPoints();
      })
      //.catch(error => this.mapLoadError(error));

      EventBus.$on('update-table', (item) => {
        this.items = this.$store.getters.getRoutesItems
        if(this.isViewForm){
          this.heatmapDataReady = false
          this.$nextTick(() => {
            this.$refs.histMapRef.$mapPromise.then(() => {
              this.$refs.histMapRef.$mapPromise.then(mapObj => {
                this.historicMapObj = mapObj;
                // this.mapInitialize();
                this.getHistoricPoints();
              })
              .catch(error => this.mapLoadError(error));
            })
          });
        } else {
          console.log(this.$refs.heatMapRef)
          this.$nextTick(() => {
            this.$refs.heatMapRef.$mapPromise.then(() => {
              this.$refs.heatMapRef.$mapPromise
              .then(mapObj => {
                this.heatMapObj = mapObj;
                this.getHeatPoints();
              })
              .catch(error => this.mapLoadError(error));
            })
          })
          this.$nextTick(() => {

          });
        }
      });
    },
    created() {
      EventBus.$on('add-items', (item) => {
          this.items.push(item)
      });

      EventBus.$on('change-range', (item) => {
        this.slideBack = item
        this.changeRange()
      });
    },
    methods: {
      convertToKm(meters) {
        return (meters/1000).toFixed(1)
      },
      convertDate(isoDate) {
        return isoDate.substring(8,10) + '/' +
               isoDate.substring(5,7) + '/' +
               isoDate.substring(2,4);
      },
      mapInitialize() {
        this.mapOptions.mapTypeControlOptions.style = this.google.maps.MapTypeControlStyle.HORIZONTAL_BAR;
        this.mapOptions.mapTypeControlOptions.position = this.google.maps.ControlPosition.TOP_RIGHT;
      },
      getHistoricPoints() {
        reporteService.historicoMapa(
          this.items[this.indexSelected].veh_id,
          this.items[this.indexSelected].log_rep_date
        ).then(res => {
          this.gpsPoints = res.data;
          for (let j = 0; j < this.gpsPoints.length; j++) {
            var obj = this.gpsPoints[j];
            obj.lng = obj.lon;
            delete obj.lon;
          }
          this.changeRange();
        });
      },
      onRowSelected(item) {
        // console.log("onRowSelected -> item", item)
        // this.indexSelected = index
        let index = -1
        if (item.length) {
          index = this.items.findIndex(i => i.veh_id === item[0].veh_id)
        }
        this.$emit('selected', index)
        this.selected = item
        // console.log("onRowSelected -> this.selected", this.selected)
      },
      onChangeView() {
        if(this.isViewForm) {
          this.isViewForm = !this.isViewForm
          if(!this.heatmapDataReady){
            this.$nextTick(() => {
              this.$refs.heatMapRef.$mapPromise.then(() => {
                this.$refs.heatMapRef.$mapPromise
                .then(mapObj => {
                  this.heatMapObj = mapObj;
                  // this.mapInitialize();
                  this.getHeatPoints();
                })
                .catch(error => this.mapLoadError(error));
                this.heatmapDataReady = true
                  })
            });
            this.heatmapDataReady = true
          }
        }
        else {
          this.isViewForm = !this.isViewForm
        }
        // TODO control de items lenght CERO
        if(!this.isViewForm) {
          this.indexSelected = -1
        } else if (this.indexSelected === -1) {
          this.indexSelected = this.$route.params.indexSelected
        }
      },
      changeRange() {
        let year = new Date(this.gpsPoints[0].ts).getFullYear();
        let month = new Date(this.gpsPoints[0].ts).getMonth();
        month = month + 1;
        let day = new Date(this.gpsPoints[0].ts).getDate();

        var fechaConsultaIni = new Date(
          year +
            "-" +
            month +
            "-" +
            day +
            " " +
            this.slideBack[0]
        );
        var fechaConsultaEnd = new Date(
          year +
            "-" +
            month +
            "-" +
            day +
            " " +
            this.slideBack[1]
        );
        this.pointsInRange = [];

        for (let i = 0; i < this.gpsPoints.length; i++) {
          let fechaTemp = new Date(this.gpsPoints[i].ts);

          if (fechaTemp >= fechaConsultaIni && fechaTemp <= fechaConsultaEnd) {
            this.pointsInRange.push(this.gpsPoints[i]);
          }
        }
        this.setPolyline();
      },
      setPolyline() {
        var mapBounds = new this.google.maps.LatLngBounds();
        var self=this
        this.pointsInRange.forEach(function(value, key, array) {
          mapBounds.extend(new self.google.maps.LatLng(value.lat, value.lng));
        });
        this.historicMapObj.fitBounds(mapBounds);
      },
      onPrev() {
        // TODO control de items lenght CERO
        if(this.indexSelected > 0) {
          this.indexSelected--
        } else {
          this.indexSelected = this.items.length - 1
        }
        this.refreshHistoricMap()
      },
      onNext() {
        // TODO control de items lenght CERO
        if(this.indexSelected < this.items.length - 1) {
          this.indexSelected ++
        } else {
          this.indexSelected = 0
        }
        this.refreshHistoricMap()
      },
      refreshHistoricMap() {
        this.$refs.histMapRef.$mapPromise.then(mapObj => {
          this.historicMapObj = mapObj;
          this.mapInitialize();
          this.getHistoricPoints();
        })
        .catch(error => this.mapLoadError(error));
      },
      async getHeatPoints() {
        if (this.heatDataPoints.length > 0){
          this.heatmap.setMap(null)
          this.heatDataPoints = []
        }
        let record = this.$store.getters.getRecordData
        let iniDate = new Date(record.dateFrom);
        let toDate = new Date(record.dateTo);
        let patentes = record.selectedVeh;

        this.iniDateString = iniDate.toISOString().substring(8, 10)+iniDate.toISOString().substring(4, 8)+iniDate.toISOString().substring(0, 4);
        this.toDateString = toDate.toISOString().substring(8, 10)+toDate.toISOString().substring(4, 8)+toDate.toISOString().substring(0, 4);
        var datesToQuery = [];
        this.heatMapBounds = new this.google.maps.LatLngBounds();

        // console.log("llamando con días -->", this.iniDateString, this.toDateString)

        const waitFor = ms => new Promise(r => setTimeout(r, ms));
        var daysToQuery=0
        for (var d = iniDate; d <= toDate; d.setDate(d.getDate() + 1)) {
          datesToQuery.push(d.toISOString().substring(0, 10));
          daysToQuery++
        }
        var currentProgress = 0

        for (const patente of patentes) {
          for (const dateRep of datesToQuery) {
            let dayRep = await reporteService.historicoMapa(
              patente.pat,
              dateRep
            ).then(res => {
              return res.data;
            });

            if (dayRep){
                dayRep.forEach(item => {
                    let LatLngPoint = new this.google.maps.LatLng(item.lat, item.lon);
                    this.heatDataPoints.push(LatLngPoint);
                    this.heatMapBounds.extend(LatLngPoint);
                })
            }

            await waitFor(500);

            currentProgress += 100/daysToQuery
            this.progressValue = (currentProgress>100)?100:currentProgress;

          }
        }

        this.heatmap = new this.google.maps.visualization.HeatmapLayer({
          data: this.heatDataPoints
        });
        this.changeGradient()
        this.changeRadius()
        this.heatmap.setMap(this.heatMapObj)
        this.heatMapObj.fitBounds(this.heatMapBounds)
      },
      changeGradient() {
        var gradient = [
          "rgba(0, 255, 255, 0)",
          "rgba(0, 255, 255, 1)",
          "rgba(0, 191, 255, 1)",
          "rgba(0, 127, 255, 1)",
          "rgba(0, 63, 255, 1)",
          "rgba(0, 0, 255, 1)",
          "rgba(0, 0, 223, 1)",
          "rgba(0, 0, 191, 1)",
          "rgba(0, 0, 159, 1)",
          "rgba(0, 0, 127, 1)",
          "rgba(63, 0, 91, 1)",
          "rgba(127, 0, 63, 1)",
          "rgba(191, 0, 31, 1)",
          "rgba(255, 0, 0, 1)"
        ];
        this.heatmap.set(
          "gradient",
          this.heatmap.get("gradient") ? null : gradient
        );
      },
      changeRadius() {
        this.heatmap.set("radius", this.heatmap.get("radius") ? null : 20);
      },
      mapLoadError(error) {
        alert("No se pudo inicializar el mapa:", error);
      }
    }
  }
  </script>
  <style scoped>
  .card-title{
    margin-top: 2px;
    margin-bottom: 2px;
    font-size: 34px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color:gray;
  }

  .card-category {
    color: darkgray;
    font-size: 18px;
  }
  .column {
    height: inherit;
  }
  p{
    text-align: left;
  }
  .info-card {
    height: 32%;
    /* max-width: 250px; */
    min-width: 200px;
  }
  .mf-routes-tracking-card{
    height: calc(100vh - 160px);
  }

  .row {
    display: flex;
    height: calc(100% - 30px);
  }
  .column {
    flex: 20%;
  }
  .map-column {
    flex: 200;
    height: 95%;
  }
  .info-card {
    margin: 0px 30px 15px 30px;
  }
  .slider-column {
    flex: 70%;
  }

  .heatmap-container {
    flex: 100%;
  }
  .buttons-column {
    flex: 30%;
  }
  </style>
