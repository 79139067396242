import Vue from "vue";
import Router from "vue-router";
import routes from './routes'
////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////
/////////
import { TokenService } from "@ingeo-mf/styleguide";
//////////

Vue.use(Router);

const router = new Router({
    routes,
    mode: 'history',
    linkActiveClass: 'active',
    scrollBehavior: (to) => {
      if (to.hash) {
        return {selector: to.hash}
      } else {
        return { x: 0, y: 0 }
      }
    }
  })

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some((record) => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some(
    (record) => record.meta.onlyWhenLoggedOut
  );
  const loggedIn = !!TokenService.getToken();
  if (!isPublic && !loggedIn) {
    return next({
      path: "/login",
      query: { redirect: to.fullPath },
    });
  }
  // Do not allow user to visit login page if they are logged in
  if (loggedIn && onlyWhenLoggedOut) {
    return next("/menu");
  }
  next();
});

export default router;
