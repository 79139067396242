<template>
  <b-modal
    v-model="show"
    title="Descarga de Recorrido Completo"
    centered
    header-bg-variant="secondary" header-text-variant="light"
    body-bg-variant="light" body-text-variant="dark"
    footer-bg-variant="light" footer-text-variant="dark"
  >
    <b-card-group deck>
      <b-card header="Incluir" align="center">
        <b-card-text align="left">
          <b-form-group >
            <b-form-checkbox v-model="selected.record"> Recorrido </b-form-checkbox>
            <b-form-checkbox v-model="selected.event"> Eventos </b-form-checkbox>
            <b-form-checkbox v-model="selected.velocity"> Velocidad </b-form-checkbox>
          </b-form-group>
        </b-card-text>
      </b-card>

      <b-card header="Parámetros" align="center">
        <b-card-text align="left" >
          <b-input-group prepend="Km">
            <b-form-input type="number" placeholder="Km/h" :disabled='isDisabled' v-model="vmax" ></b-form-input>
          </b-input-group>
          <br>
          <b-input-group prepend="Tol">
            <b-form-input type="number" placeholder="Minutos" :disabled='isDisabled' v-model="tol"></b-form-input>
          </b-input-group>
        </b-card-text>
      </b-card>
    </b-card-group>

    <!-- Submit and Cancel Buttons -->
    <template v-slot:modal-footer>
      <div class="float-right">
         <b-button-group>
              <b-button variant="success" class="mr-3" @click="onSubmit(item)">
                <download-excel
                  type = "xls"
                  worksheet = "Reportes"
                  :name = "fullFileName"
                  :data = "items">
                 <div ><b-icon icon="download" class="mr-1"></b-icon>Export</div>
                </download-excel>
              </b-button>

            </b-button-group>
          <b-button variant="danger" @click="onCancel()">
          <b-icon icon="x" class="mr-1"></b-icon>
          Cancelar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import dataTable from "./data"
  import resources from '@/mixins/resourcesReport'
  import JsonExcel from 'vue-json-excel'

/// #if process.env.VUE_APP_DEVMODE === 'standalone'
import reporteService from "@/../../ingeo-mf-styleguide/src/services/reporte"
/// #else
import { reporteService } from "@ingeo-mf/styleguide";
/// #endif

  export default {
    props: {
      show: {
        required: true,
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
       checked: true,
        selected:  [
            { record: false },
            { event: false },
            { velocity: false}
        ],
        options: [
          { text: 'Recorrido', value: 'record' },
          { text: 'Evento', value: 'event' },
          { text: 'Velocidad', value: 'velocity' }
        ],
         dateFrom: "", //"2020-11-01",
         dateTo: "", //"2020-11-15",
        vehiculosSeleccionados: [
                      { pat: 'HTN715' },
                      { pat: 'NKV128' }
        ],

        items: [], //items: dataTable.items,
        indexSelected: 0,
        fileName: "Registros",
        vmax: "",
        tol: "",

      }
    },
   computed: {
      isDisabled: function() {
        return !this.selected.velocity;
      },
       fullFileName() {
        let now = new Date();
        let nowDay = now.toISOString().slice(0,10).replace(/-/g,"");
        //console.log("fullFileName -> nowDay", nowDay)
        return `${this.fileName.trim().toLowerCase()}_${nowDay}.xls`
      },
      item() {
        if(this.indexSelected >= 0) {
          return this.items[this.indexSelected]
        }
        return item = []
      },
    },
    methods: {
      onSubmit(item) {
         this.selectedVeh = this.$store.getters.getSelectedVeh
         this.dateFrom =  this.$store.getters.getDateFrom
         this.dateTo =  this.$store.getters.getDateTo
         this.selectedVeh.forEach(vehicle => {
          reporteService.resumenRecorridos( vehicle.pat, this.dateFrom, this.dateTo)
          .then(res => {
           res.data.forEach(route => {
              route.log_week_day = this.showNameDay(route.log_rep_date)
              route.log_distance_km = (route.log_rep_distance/1000).toFixed(1) + " km"
              route.max_vel_string = (route.log_rep_max_speed) + " km/h"

                 this.items.push({veh_id: route.veh_id, log_week_day:  route.log_week_day, log_rep_date: route.log_rep_date, log_rep_trip_time: route.log_rep_trip_time,
                              log_rep_stop_time: route.log_rep_stop_time, log_week_day: route.log_week_day, max_vel_string :route.max_vel_string})


            })
           const result = this.items.filter(item => item.max_vel_string === this.vmax);
                console.log("🚀 ~ file: ModalExport.vue ~ line 134 ~ onSubmit ~ result", result)
          })
        })

      console.log("🚀 ~ file: ModalExport.vue ~ line 141 ~ onSubmit ~ this.items", this.items)

       let urlxls =
              process.env.VUE_APP_API_REP_URL +
              "index.php?veh=" +
              //TODO: se toma por ahora el primer elemento del multiselect
              this.selectedVeh[0].pat +
              "&from=" +
              this.formatDate(this.dateFrom) +
              "&to=" +
              this.formatDate(this.dateTo);

        if (this.selected.record) urlxls += "&trip=yes";
        else urlxls += "&trip=no";

        if (this.selected.event) urlxls += "&event=yes";
        else urlxls += "&event=no";

        if (!this.selected.velocity)
            if (!this.selected.velocity)
        if (!this.selected.velocity)
        {
            urlxls += "&vel=no";
        }
        else {
          urlxls +=
            "&vel=yes" + "&veltol=" + this.tol + "&velmax=" + this.vmax;

        }
        urlxls += "&xval=$5INzGz|7LoUlj!)pvTO9Ur[Oz[0}"
        // + process.env.VUE_APP_INDEX_KEY;
        //input.target.files ? event.target.files[0] : null;

        window.open(urlxls, "_blank");

        this.vmax = "",
        this.tol = "",
        this.selected.velocity= false,
        this.selected.event= false,
        this.selected.record= false,

        // TODO acciones de llamada a API para pedir envio de EXCEL
        console.log('onSubmit')
        this.onClose()
      },
      showNameDay(value){
        let days = ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'];
        let current_datetime = (new Date(value)).getDay();
        return days[current_datetime];
      },
      formatDate(actualDate) {
            var newdate = new Date(actualDate);
            return newdate.toISOString().substring(0, 10);
      },
      onCancel() {
        console.log('onCancel')
        this.onClose()
      },
      onClose() {
        this.$emit('onClose')
      },
    },
  }
</script>
