import Home from '@/views/Home/Index'
import Reports from '@/views/Reports/Index'
import Report from '@/views/Report/Index'
import Tracking from '@/views/Tracking/Index'
import TimeLine from '@/views/TimeLine/Index'
import HeatMap from '@/views/Reports/HeatMap'

const routes = [
  {
    path: '/routes',
    name: 'HomeReport',
    component: Home,
    meta: {
        public: false,          // Solo accede si está logueado
        onlyWhenLoggedOut: false
    }
  },
  // ruta temporal... a reemplazar por report
  // {
  //   path: '/routes/reports',
  //   name: 'Historicos',
  //   component: Reports,
  //   meta: {
  //       public: false,
  //       onlyWhenLoggedOut: false
  //   }
  // },
   // ruta temporal... a reemplazar por tracking vista global
  // {
  //   path: '/routes/heatmap',
  //   name: 'Mapa de Calor',
  //   component: HeatMap,
  //   meta: {
  //     public: false,
  //     onlyWhenLoggedOut: false
  //   }
  // },
  {
    path: '/routes/report',
    name: 'Historicos',
    component: Report,
    meta: {
        public: false,
        onlyWhenLoggedOut: false
    }
  },
  {
    path: '/routes/tracking',
    name: 'Recorridos',
    component: Tracking,
    meta: {
        public: false,
        onlyWhenLoggedOut: false
    }
  },
  {
    path: '/routes/tracking/:indexSelected',
    name: 'TrackingDetail',
    component: Tracking,
    meta: {
        public: false,
        onlyWhenLoggedOut: false
    }
  },
  {
    path: '/routes/timeline',
    name: 'TimeLine',
    component: TimeLine,
    meta: {
        public: false,
        onlyWhenLoggedOut: false
    }
  },
  {
    path: '/routes/timeline/:indexSelected',
    name: 'TimeLineDetail',
    component: TimeLine,
    meta: {
        public: false,
        onlyWhenLoggedOut: false
    }
  },
  // {path: '*', component: Home}   // cambiar a not found?
];

export default routes
