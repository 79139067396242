const moduleName = "Reportes";
const menuItems = [
  {
    id: 1,
    title: "Reportes",
    type: "dropdown",
    items: [
      { id: 11, type: "header", name: "Uso", url: "" },
      { id: 12, type: "link", name: "Registros", url: "/routes/report" },
      { id: 12, type: "link", name: "Mapa de Calor", url: "/routes/tracking" },
      // { id: 13, type: "link", name: "TimeLine", url: "/routes/timeline" },
      // { id: 14, type: "divider" },
      // { id: 15, type: "header", name: "Reportes UI", url: "" },
      // { id: 16, type: "link", name: "Reportes", url: "/routes/reports" },
    ],
  },
  // {
  //   id: 2,
  //   title: "Mantenimiento",
  //   type: "dropdown",
  //   items: [
  //     { id: 21, type: "header", name: "Mantenimiento", url: "" },
  //     { id: 22, type: "link", name: "Perfiles", url: "/config/mantperfiles" },
  //     { id: 23, type: "link", name: "Items", url: "/config/mantitems" },
  //     { id: 24, type: "link", name: "Relaciones", url: "/config/pivot" },
  //   ],
  // },
];
export default { moduleName, menuItems };
