<template>
  <b-card id="content-wrapper">

    <slot name="Title"></slot>

    <br>

    <!-- TODO ver layout del HEADER del rango de fecha y vehiculos -->
    <b-card>
      <b-button-toolbar justify key-nav>
        <b-button-group>
          <b-input-group class="mr-2">
            <b-input-group-prepend>
              <b-input-group-text class="d-none d-md-block">
                Desde
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-datepicker id="input-1"
              v-model="dateFrom"
              placeholder="" hideHeader
              reset-button close-button
              label-reset-button="Limpiar" label-close-button="Cerrar"
              labelHelp="Use el cursor para navegar"
               :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
               locale="es"
              :date-disabled-fn="dateFromDisabled"
            >
            </b-form-datepicker>
          </b-input-group>

          <b-input-group class="mr-2">
            <b-input-group-prepend>
              <b-input-group-text class="d-none d-md-block">
                Hasta
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-datepicker id="input-2"
              v-model="dateTo"
              placeholder="" hideHeader
              :disabled="isDateToDisabled"
              reset-button close-button
              label-reset-button="Limpiar" label-close-button="Cerrar"
              labelHelp="Use el cursor para navegar"
              :date-disabled-fn="dateToDisabled"
              locale="es"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
            >
          </b-form-datepicker>
          </b-input-group>

          <b-input-group-prepend>
            <b-input-group-text class="d-none d-md-block" >
              Vehículo
            </b-input-group-text>
          </b-input-group-prepend>
          <b-input-group v-if="this.vehiculosDisponibles">
            <multiselect
              v-model="multiSelectValue"
              :options="vehiculos"
              :multiple="true"
              :searchable="true"
              :close-on-select="true"
              :show-labels="true"
              group-values="grupoVeh"
              group-label="name"
              :group-select="true"
              placeholder="Ingrese un dominio"
              deselectGroupLabel="Deseleccionar el Grupo"
              deselectLabel="Deseleccionar el vehículo"
              selectedLabel="Seleccionado"
              selectGroupLabel="Seleccionar el Grupo"
              selectLabel="Seleccionar el vehículo"
              track-by="pat"
              label="pat"
              :value="multiSelectCurrentVal"
              :max-height="300"
              :disabled="isVehDisabled"
              :taggable="true"
            >
            <span slot="noResult">No se encontró ningún vehículo..</span>
            </multiselect>
          </b-input-group>
        </b-button-group>

        <b-button-group>
          <b-button>
            <div class="d-none d-md-block" @click="getRoutesData()"><b-icon icon="arrow-repeat" class="mr-1"></b-icon>Actualizar</div>
            <div class="d-md-none"><b-icon icon="arrow-repeat"></b-icon></div>
          </b-button>
        </b-button-group>

      </b-button-toolbar>
    </b-card>

    <br>

    <slot name="RecordLayout"></slot>

  </b-card>
</template>

<script>

  import dataTable from "./data"


/// #if process.env.VUE_APP_DEVMODE === 'standalone'
import vehiculosService from "@/../../ingeo-mf-styleguide/src/services/vehiculos"
/// #else
import { vehiculosService } from "@ingeo-mf/styleguide";
/// #endif

  import EventBus from '@/eventBus'
  import Multiselect from "vue-multiselect";

  import store from "@/store"
  import { mapState } from "vuex";
  import moment from "moment";
  import _ from 'lodash'

  export default {

    name: 'Record',
    components: { Multiselect },
    data() {
      // TODO reemplazo de data.options por resultado de query con lista de vehiculos
      return {

        DatePickerFormat: 'yyyy-MM-dd',
        // dateFrom: "",
        // dateTo: "",

        dateFrom: moment().startOf('month').format('YYYY-MM-DD hh:mm:ss'), //moment().startOf('month').format('YYYY-MM-DD HH:mm'),
         dateTo: moment().endOf('month').format('YYYY-MM-DD HH:mm'),

        dateRange: 15,
        vehiculos: [],
        vehiculosDisponibles: false,
        multiSelectValue: "",
        multiSelectCurrentVal: "",

      }
    },

    mounted() {
      let currentRecord = this.$store.getters.getRecordData
      // Asigna los valores de los campos si ya existían.
      this.dateFrom = currentRecord.dateFrom ? currentRecord.dateFrom : moment().startOf('day').format('YYYY-MM-DD hh:mm:ss'), //currentRecord.dateFrom
      this.dateTo = currentRecord.dateTo ?  currentRecord.dateTo: moment().startOf('day').format('YYYY-MM-DD hh:mm:ss'), //currentRecord.dateTo
      this.multiSelectValue = currentRecord.selectedVeh
      this.getVehiculos()
    },

    computed: {
      ...mapState(["grupos"]),
      isDateToDisabled() {
        return this.dateFrom === "" ? true:false
      },
      isVehDisabled() {
        return this.dateTo === "" ? true:false
      },
      dateFromYMD() {
        if(!this.isDateToDisabled) {

          const selectDate = new Date(this.dateFrom)
          const selectYMD = selectDate.toISOString().split('T')[0]
          return selectYMD
        }
        const today = new Date()
        const todayYMD = today.toISOString().split('T')[0]
        return todayYMD
      }
    },

    methods: {
      // groupArrayOfObjects(list, key) {
      //   return list.reduce(function(rv, x) {
      //     (rv[x[key]] = rv[x[key]] || []).push(x);
      //     return rv;
      //   }, {});
      // },
      getRoutesData() {
        var dd = String(new Date(this.dateTo).getDate()).padStart(2, '0');
        var mm = String(new Date(this.dateTo).getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = new Date(this.dateTo).getFullYear();

        let dateToString =  yyyy + '-' + mm + '-' + dd;

        this.$store.commit("setRecordData", {
          dateFrom: this.dateFrom,
          dateTo: this.dateTo, //dateToString, ///Lo cambié
          vehiculosSeleccionados: this.multiSelectValue
        });
        EventBus.$emit('update-table')
      },
      getVehiculos() {
        vehiculosService.index().then(response => {
          this.$store.commit("setGrupos", {
            gruposVehiculos: response.data
          });
          this.formatData();
        });
      },
      //FORMATEA LOS VEHICULOS EN CADA UNO DE LOS GRUPOS CORRESPONDIENTES.
      formatData() {
        var i;
        var j;
        for (i = 0; i < this.grupos.length; i++) {
          var grupo = {
            name: this.grupos[i].grupo_nombre,
            grupoVeh: []
          };
          for (j = 0; j < this.grupos[i].grupos_vehiculos.length; j++) {
            var veHSel = {
              pat: this.grupos[i].grupos_vehiculos[j].veh_id,
              key: this.grupos[i].grupos_vehiculos[j].veh_id
            };
            grupo.grupoVeh.push(veHSel);
          }
          this.vehiculos.push(grupo);
        }
        this.vehiculosDisponibles = true;
      },
      dateFromDisabled(ymd, date) {
       const today = new Date()
       const todayYMD = moment(today).startOf('day').format('YYYY-MM-DD hh:mm:ss')  //today.toISOString().split('T')[0] LO CAMBIÉ
      return ymd >= todayYMD
      },


      dateToDisabled(ymd, date) {
        const today = new Date()
        const todayYMD = moment(today).startOf('day').format('YYYY-MM-DD hh:mm:ss') //today.toISOString().split('T')[0]

        const dateFrom = new Date(this.dateFromYMD)

        const dateToMsec = dateFrom.getTime() + this.dateRange * (1000 * 3600 * 24)
        const dateToDay = new Date(dateToMsec)

        const dateToYMD = dateToDay.toISOString().split('T')[0]

        const day = new Date()

        const firstDay = new Date(day.getFullYear(), day.getMonth(), 1);

      var a = moment(this.dateFrom)
      var b = moment()
      let diffDate = b.diff(a, 'days')

        if ((this.dateFrom === moment(firstDay).format("YYYY-MM-DD"))) {
          this.dateTo = moment().endOf('day').format('YYYY-MM-DD');
        } else if (dateFrom.getMonth() === day.getMonth()) {
          this.dateTo = moment().endOf('day').format('YYYY-MM-DD') //day
        } else if (diffDate < this.dateRange)  {
           this.dateTo = moment().endOf('day').format('YYYY-MM-DD')
        } else  {
          this.dateTo = dateToYMD
        }
     return ymd >= todayYMD || ymd < this.dateFromYMD || ymd >= dateToYMD
      }

     }
  }
</script>

<style scoped>
.multiselect {
  min-width: 250px;
}
</style>
