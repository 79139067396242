import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({

    state: {
        grupos: [],
        map: {},
        marker: {},
        route: [],
        routeChart: [],
        patenteSelected: '',
        dateFrom: '',
        dateTo: '',
        routes: [],
        positionQuery: {
            minutesBackward: 90,
            metersBackward: 10000,
            unityForBackward: 'time'
        },
        settings: {
            sidebarMini: Boolean
        },
        positionLastInfo:{
            distance:'',
            vmax:'',
            positionAge:'',
            vbat:''
        },
        vehiculosSeleccionados: [],
        reporte: {},
        detalleReporte: {
          patente: '',
          idReporte: '',
          fechaDesde: '',
          fechaHasta: '',
          vehiculos: '',
          tableData: []
        },
        historicoMapa: {}
    },

    mutations: {

        setVbat(state, payload) {
            state.positionLastInfo.vbat = payload
        },

        setMinutesBackward(state, payload) {
            state.positionQuery.minutesBackward = payload
        },

        setMetersBackward(state, payload) {
            state.positionQuery.metersBackward = payload
        },

        selectPatente(state, payload) {
            state.patenteSelected = payload.patenteVehiculo
        },

        setGrupos(state, payload) {
            state.grupos = payload.gruposVehiculos
        },

        setMap(state, payload){
            state.map = payload.mapa
        },

        setMarker(state, payload) {
            state.marker = payload.marker
        },

        setDistance(state, payload) {
            state.positionLastInfo.distance = payload
        },

        setRoute(state, payload) {

            // TODO: Agregar luego para recortar ruta en función de metersBackward

            var oldestTime = new Date()
            oldestTime.setMinutes(oldestTime.getMinutes() - state.positionQuery.minutesBackward)

            var validRoute = []
            var validRouteChart = []
            var vmax = 0;
            var nowDate = new Date()
            var newestAgeSeconds = ( new Date().getTime() -
                                     new Date(payload.rutaVehiculo[0].log_gps_timegps).getTime() ) / 1000

            // payload.rutaVehiculo.slice().reverse().forEach( function(value, key, array) {
            for (let i=payload.rutaVehiculo.length-1 ; i>=0 ; i--) {

                let value = payload.rutaVehiculo[i]

                let timeGps = new Date(value.log_gps_timegps)

                if ( timeGps.getTime() >= oldestTime.getTime()) {
                    validRoute.push(value)
                    var obj = {}
                    obj.x = timeGps.getTime();
                    obj.y = value.vel
                    obj.lat = value.lat
                    obj.lng = value.lng

                    validRouteChart.push(obj)

                    if (value.vel > vmax)
                    vmax = value.vel
                }
            }

            if (validRoute.length) {
                state.route = validRoute
            }
            else {
                state.route.length = 0;
                state.route.push(payload.rutaVehiculo[0])
            }
            state.routeChart.slice(0)
            state.routeChart = validRouteChart
            state.positionLastInfo.vmax = vmax

            if (newestAgeSeconds < 0 ) newestAgeSeconds = 30
            state.positionLastInfo.positionAge = newestAgeSeconds

            // state.route = payload.rutaVehiculo
            // var k
            // var converted = []
            // for (k = 0; k < payload.rutaVehiculo.length; k++) {
            //     var obj = {}
            //     obj.x = (new Date(payload.rutaVehiculo[k].log_gps_timegps)).getTime();
            //     obj.y = payload.rutaVehiculo[k].vel
            //     obj.lat = payload.rutaVehiculo[k].lat
            //     obj.lng = payload.rutaVehiculo[k].lng
            //     converted.push(obj);
            // }
            // state.routeChart = (converted)
        },

        setRecordData(state, payload) {
            state.dateFrom = payload.dateFrom
            state.dateTo = payload.dateTo
            state.vehiculosSeleccionados = payload.vehiculosSeleccionados
        },

        setRoutesResult(state, payload) {
            state.routes = payload.items
        },

        setDetalleReporte(state,payload){
          state.detalleReporte.idReporte = payload.idReporte
          state.detalleReporte.patente = payload.patente
          state.detalleReporte.fechaDesde = payload.fechaDesde
          state.detalleReporte.fechaHasta = payload.fechaHasta
          state.detalleReporte.tableData = payload.tableData
          state.detalleReporte.vehiculos = payload.vehiculos
        },

        setSettings(state, payload) {
            state.settings.sidebarMini = payload.sidebarMini
        },

        setStorymap(state, payload) {
            state.historicoMapa = payload.fila
        }
    },

    getters: {
        getSidebarMini: state => {
          return state.settings.sidebarMini
        },
        getDateFrom: state => {
            return state.dateFrom
        },
        getDateTo: state => {
            return state.dateTo
        },
        getSelectedVeh: state => {
            return state.vehiculosSeleccionados
        },
        getRecordData: state => {
            return {
                dateTo: state.dateTo,
                dateFrom: state.dateFrom,
                selectedVeh: state.vehiculosSeleccionados
            }
        },
        getRoutesItems: state => {
            return state.routes
        }
    },

    actions: {
        selectPatente(state, data) {
            return new Promise((resolve, reject) => {
                state.patenteSelected = data
                resolve;  // Let the calling function know that http is done. You may send some data back
            })
        }
    }
})

export default store
