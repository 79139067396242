import "./set-public-path";
import singleSpaVue from "single-spa-vue";
import Vue from 'vue'
import './pollyfills'

// import "./assets/css/global-mf-routes.css";

// import HighchartsVue from 'highcharts-vue'
// Vue.use(HighchartsVue)
import "./plugins/bootstrapVue";
import "./plugins/vueJsonExcel";
import "./plugins/highchartsVue";

////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
/////////
import { TokenService } from "@ingeo-mf/styleguide";
//////////


import './map'
import App from './App.vue'
import store from './store'
import router from "./router";
import * as VueGoogleMaps from 'vue2-google-maps'

console.log("Running mode: ", process.env.VUE_APP_DEVMODE);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCaYpPmGqqF7RqcFbAAOgVL0mZaee2JmcA',
    libraries: ['drawing', 'places', 'geometry', 'visualization'],
  },
})

////////////////////////////////////////////////////
/////////
/////////
////////
////////////
////////////
////////////////////////////////////////////////////////
/////////////////////////
//////////////////////////////////////////////////////////////
//////
////
/////////////
////////////////
////
////////////////////////
//////////////////

/////////

Vue.config.productionTip = false;
const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h) {
      // TokenService.saveToken(this.token);
      return h(App, { props: { token: this.token } });
    },
    router,
    store,
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;

//////////
