<template>
  <div id="openDialog" class="card row" style="box-shadow:none; background: #e9ecef;">
    <div class="col-md-6">
      <card style="height: 300px;">
        <h4 class="card-title">Planilla</h4>
        <p-switch v-model="switchTrip.recorridos" class="m-2" type="primary" on-text="ON" off-text="OFF"></p-switch>
        <span class="etiquetas">Recorridos</span>
        <br />
        <br />
        <p-switch v-model="switchTrip.eventos" class="m-2" type="primary" on-text="ON" off-text="OFF"></p-switch>
        <span class="etiquetas">Eventos</span>
        <br />
        <br />
        <p-switch v-model="switchTrip.velocidad" class="m-2" type="primary" on-text="ON" off-text="OFF"></p-switch>
        <span class="etiquetas">Velocidad</span>
        <br />
      </card>
    </div>
    <div class="col-md-6">
      <card style="height: 300px;">
        <form class="form-horizontal">
          <h4 slot="header" class="card-title">Parametros</h4>
          <div class="row">
            <label class="col-md-3 col-form-label">Vel. Max.</label>
            <div class="col-md-8">
              <fg-input v-if="switchTrip.velocidad" placeholder="km / h" v-model="vmax" ></fg-input>
              <fg-input v-else disabled placeholder="km / h" v-model="vmax" ></fg-input>
            </div>

            <label class="col-md-3 col-form-label">Tolerancia</label>
            <div class="col-md-8">
              <fg-input v-if="switchTrip.velocidad" placeholder="Minutos" style="margin-bottom: 24%;" v-model="tol" ></fg-input>
              <fg-input v-else placeholder="Minutos" disabled style="margin-bottom: 24%;" v-model="tol" ></fg-input>
            </div>
          </div>
        </form>
      </card>
    </div>
    <div style="margin: auto; margin-top: 2%;">
      <p-button type="success" @click="downloadXls()">
        <i slot="label" class="nc-icon nc-check-2"></i>
        Descargar
      </p-button>
      <p-button type="danger" @click="closeDialog()">
        <i slot="label" class="nc-icon nc-simple-remove"></i>
        Cancelar
      </p-button>
    </div>
  </div>
</template>

<script>
/// #if process.env.VUE_APP_DEVMODE === 'standalone'
import { PButton, Card, PSwitch, FgInput } from "../../../../ingeo-mf-styleguide/src/UIComponents";
/// #else
import { PButton, Card, PSwitch, FgInput } from "@ingeo-mf/styleguide";
/// #endif

import Vue from "vue";
import { Table, TableColumn, DatePicker, Select, Option, Tag } from "element-ui";
import { mapState } from "vuex";


import store from "@/store";
export default {
  components: {
    PButton,
    Card,
    PSwitch,
    FgInput
  },
  computed: {
    ...mapState(["vehiculosSeleccionados", "dateFrom", "dateTo"])
  },
  data() {
    return {
      switchTrip: {
        recorridos: false,
        eventos: false,
        velocidad: false
      },
      vmax: "",
      tol: ""
    };
  },
  methods: {
    closeDialog() {
      this.$emit('dialog-close')
    },
    downloadXls() {
      let urlxls =
        process.env.VUE_APP_API_REP_URL +
        "index.php?veh=" +
        this.vehiculosSeleccionados[0].pat +
        "&from=" +
        this.formatDate(this.dateFrom) +
        "&to=" +
        this.formatDate(this.dateTo);

      if (this.switchTrip.recorridos) urlxls += "&trip=yes";
      else urlxls += "&trip=no";

      if (this.switchTrip.eventos) urlxls += "&event=yes";
      else urlxls += "&event=no";

      if (!this.switchTrip.velocidad) urlxls += "&vel=no";
      else {
        urlxls +=
          "&vel=yes" + "&veltol=" + this.tol + "&velmax=" + this.vmax;
      }

      urlxls += "&xval=$5INzGz|7LoUlj!)pvTO9Ur[Oz[0}";// + process.env.VUE_APP_INDEX_KEY;
      window.open(urlxls, "_blank");
      this.closeDialog();
    },
    formatDate(actualDate) {
      var newdate = new Date(actualDate);
      return newdate.toISOString().substring(0, 10);
    }
  }
}
</script>

<style>
#openDialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  max-width: 100%;
  height: 450px;
  max-height: 100%;
  z-index: 1010;
  padding: 25px 10px;
}
</style>
