<template>
  <div class="extended-forms">
    <div class="card">
        <div class="card-body" style="padding-top: 0">
            <div class="row">
                <div class="col-md-2">
                    <h4 style="margin-top:0" class="card-title">Desde</h4>
                    <div class="form-group">
                    <el-date-picker
                        v-model="dateFrom"
                        type="date"
                        placeholder="  Seleccionar un dia"
                        :picker-options="pickerOptions"
                    ></el-date-picker>
                    </div>
                </div>
                <div class="col-md-2">
                    <h4 style="margin-top:0" class="card-title">Hasta</h4>
                    <div class="form-group">
                    <el-date-picker
                        v-model="dateTo"
                        type="date"
                        placeholder="  Seleccionar un dia"
                        :picker-options="pickerOptions"
                    ></el-date-picker>
                    </div>
                </div>
                <div class="col-md-4">
                    <h4 style="margin-top:0" class="card-title">Vehículo</h4>
                    <fg-input v-if="this.vehiculosDisponibles">
                    <multiselect
                        v-model="multiSelectValue"
                        :options="vehiculos"
                        :multiple="true"
                        :searchable="true"
                        :close-on-select="true"
                        :show-labels="true"
                        group-values="grupoVeh"
                        group-label="name"
                        :group-select="true"
                        placeholder="Ingrese un dominio"
                        deselectGroupLabel="Presione para deseleccionar el Grupo"
                        deselectLabel="Presione para deseleccionar el vehículo"
                        selectedLabel="Seleccionado"
                        selectGroupLabel="Presione para seleccionar el Grupo"
                        selectLabel="Presione para seleccionar el vehículo"
                        track-by="pat"
                        label="pat"
                        :value="multiSelectCurrentVal"
                        :max-height="300"
                    >
                        <span slot="noResult">No se encontró ningún vehículo..</span>
                    </multiselect>
                    </fg-input>
                </div>
                <div class="col-md-1">
                    <p-button
                    class="form-buttons report-buttons"
                    type="neutral"
                    round
                    @click="updateTable()"
                    > <i class="nc-icon nc-refresh-69" style="font-size: 2.5em;"></i>
                      <p style=" padding-top: 15px; margin-bottom: 0px; font-size: 1.1em;">Actualizar</p>
                    </p-button>
                </div>
                <div class="col-md-1">
                    <p-button
                    class="form-buttons report-buttons"
                    type="neutral"
                    round
                    @click="openDialog()"
                    > <i class="nc-icon nc-cloud-download-93" style="font-size: 2.5em;"></i>
                      <p style=" padding-top: 15px; margin-bottom: 0px; font-size: 1.1em;">Descargar</p>
                    </p-button>
                </div>
                <div class="col-md-1">
                    <p-button
                    class="form-buttons report-buttons"
                    type="neutral"
                    round
                    @click="heatMap()"
                    > <i class="nc-icon nc-map-big" style="font-size: 2.5em;"></i>
                      <p style=" padding-top: 15px; margin-bottom: 0px; font-size: 1.1em;">HeatMap</p>
                    </p-button>
                </div>
            </div> <!--  row -->
        </div>
    </div>

    <div id="modalReports"></div>
        <div class="card">
        <div class="card-content table-responsive table-full-width" style="overflow: hidden; margin-bottom: 0px">
        <el-table
          :data="total"
          style="overflow: hidden;"
          :show-header="false"
        >
          <el-table-column>
            <template @click="sortBy()" slot-scope="scope"><b>TOTALES</b></template>
          </el-table-column>

           <el-table-column>
            <template slot-scope="scope"><b>  </b></template>
          </el-table-column>

          <el-table-column>
            <template slot-scope="scope"><b>{{Math.floor(scope.row.distance/1000)}} Km</b></template>
          </el-table-column>

          <el-table-column>
            <template slot-scope="scope"><b>{{scope.row.travel}}</b></template>
          </el-table-column>

          <el-table-column>
            <template slot-scope="scope"><b>{{scope.row.stopped}}</b></template>
          </el-table-column>

          <el-table-column>
            <template slot-scope="scope"><b>{{scope.row.vmax}} Km/h</b></template>
          </el-table-column>

          <el-table-column>
            <template slot-scope="scope"></template>
          </el-table-column>
        </el-table>
      </div>

    </div>

    <div class="card">
      <div class="card-content table-responsive table-full-width" style="overflow: hidden; padding-bottom: 14px; margin-bottom: 0px">
        <el-table
          stripe
          :data="tableData"
          style="overflow: hidden;"
          :cell-style="{padding: '2px', height: '20px'}"
          :row-class-name="WeekendDays"
        >
          <el-table-column onclick="alert();" label="Fecha">
            <template @click="sortBy()" slot-scope="scope">{{ d = changeFormatDay(scope.row.log_rep_date)}}</template>
          </el-table-column>

           <el-table-column style="color:red;" onclick="alert();" label="Día">
            <template @click="sortBy()" slot-scope="scope">{{ scope.row.log_week_day }}</template>
          </el-table-column>

          <el-table-column label="Distancia">
            <template slot-scope="scope">{{Math.floor(scope.row.log_rep_distance/1000)}} Km</template>
          </el-table-column>

          <el-table-column label="En Viaje">
            <template slot-scope="scope">{{scope.row.log_rep_trip_time}}</template>
          </el-table-column>

          <el-table-column label="Parado">
            <template slot-scope="scope">{{scope.row.log_rep_stop_time}}</template>
          </el-table-column>

          <el-table-column label="Vel. Máx">
            <template slot-scope="scope">{{scope.row.log_rep_max_speed}} Km/h</template>
          </el-table-column>

          <el-table-column
            class-name="action-buttons td-actions"
            align="right"
            label="Acciones">
              <template slot-scope="props">
                <p-button type="neutral" size="sm" icon @click="goToStoryMap(props.$index, props.row)">
                  <i class="nc-icon nc-pin-3" style="font-size: 1em; padding-top: 0.1em;"></i>
                </p-button>
                <p-button type="neutral" size="sm" icon @click="showDetail(props.row)">
                  <i class="nc-icon nc-bullet-list-67" style="font-size: 1em; padding-top: 0.1em;"></i>
                </p-button>
                <p-button type="neutral" size="sm" icon @click="downloadDetail(props.row)">
                  <i class="nc-icon nc-cloud-download-93" style="font-size: 1em; padding-top: 0.1em;"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
      </div>
    </div>

    <!-- Dialogo Modal -->
    <div class="modal-overlay" id="modal-overlay" v-if="showDialog">
    </div>
    <download-dialog
      @dialog-close="onDialogClose"
      v-if="showDialog">
    </download-dialog>

  </div>
</template>

<script>
/// #if process.env.VUE_APP_DEVMODE === 'standalone'
import { PButton, Card, StatsCard, PSwitch, FgInput } from "../../../../ingeo-mf-styleguide/src/UIComponents";
/// #else
import { PButton, Card, PSwitch, StatsCard, FgInput } from "@ingeo-mf/styleguide";
/// #endif

import Vue from "vue";
import { Table, TableColumn, DatePicker, Select, Option, Tag } from "element-ui";
import { mapState } from "vuex";
import swal from "sweetalert2";

/// #if process.env.VUE_APP_DEVMODE === 'standalone'
import vehiculosService from "@/../../ingeo-mf-styleguide/src/services/vehiculos"
import reporteService from "@/../../ingeo-mf-styleguide/src/services/reporte"
/// #else
import { vehiculosService } from "@ingeo-mf/styleguide";
import { reporteService } from "@ingeo-mf/styleguide";
/// #endif

import store from "@/store";

import lang from 'element-ui/lib/locale/lang/es'
import locale from 'element-ui/lib/locale'
// configure language
locale.use(lang)

import Multiselect from "vue-multiselect";
import DownloadDialog from "./DownloadDialog";

Vue.use(Table);
Vue.use(TableColumn);
Vue.use(PButton);

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Option.name]: Option,
    [Select.name]: Select,
    PSwitch,
    Multiselect,
    PButton,
    DownloadDialog,
    FgInput
  },
  computed: {
    ...mapState(["grupos"])
  },
  data() {
    return {
      showDialog: false,
      d: '',
      day: '',
      totalReady: false,
      search: "",
      multiSelectValue: "",
      multiSelectCurrentVal: "",
      tableData: [],
      total: [{
        distance: '',
        travel: '',
        stopped: '',
        vmax: ''
      }],
      pickerOptions: {
        shortcuts: [
          {
            text: "Hoy",
            onClick(picker) {
              picker.$emit("pick", new Date());
            }
          },
          {
            text: "Ayer",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            }
          },
          {
            text: "Sem. pasada",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            }
          }
        ]
      },
      dateFrom: "",
      dateTo: "",
      vehiculos: [],
    //   vehiculosSeleccionados: [],
      vehiculosDisponibles: false,
      vehiculoSeleccionado: ""
    };
  },
  mounted() {
    this.getVehiculos();
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    //TODO: Revisar y mejorar este mecanismo de preservación del estado de la consulta
    this.tableData = this.$store.state.detalleReporte.tableData
    this.dateFrom = this.$store.state.detalleReporte.fechaDesde
    this.dateTo = this.$store.state.detalleReporte.fechaHasta
    this.multiSelectValue = this.$store.state.detalleReporte.vehiculos
    if (this.tableData.length > 0) {
      this.calculateTotal()
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    // this.totalReady = false
  },
  methods: {
    // onChange(value) {
    //   this.vehiculoSeleccionado = value[0].pat;
    //   this.vehiculosSeleccionados[0] = value[0].pat;
    // },
    WeekendDays({row, rowIndex}) {
        // let day = his.showNameDay(row.log_rep_date)
        if (row.log_week_day === "Sab" || row.log_week_day === "Dom") {
            return 'weekend-day'
        }
     },
    showNameDay(value){
      let days = ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'];
      let current_datetime = (new Date(value)).getDay();
      return days[current_datetime];
    },
    changeFormatDay(value){
        var iniDate = new Date(value);
        return iniDate.toISOString().substring(8, 10)
                +iniDate.toISOString().substring(4, 8)
                +iniDate.toISOString().substring(0, 4);
    },
    heatMap(index, row){
      this.$store.commit("setDetalleReporte", {
        patente: this.multiSelectValue[0].pat,
        tableData: this.tableData,
        fechaDesde: this.dateFrom,
        fechaHasta: this.dateTo,
        vehiculos: this.multiSelectValue,
      });
      this.$router.push({ path: "heatmap" })
    },
    goToStoryMap(index, row){
      this.$store.commit('setStorymap' ,{
        fila: row
      })
      this.$store.commit("setDetalleReporte", {
        patente: row.veh_id,
        idReporte: row.log_rep_id,
        tableData: this.tableData,
        fechaDesde: this.dateFrom,
        fechaHasta: this.dateTo,
        vehiculos: this.multiSelectValue,
      });
      this.$router.push({ path: "storymap" })
    },

    showDetail(row) {
      this.$store.commit("setDetalleReporte", {
        patente: row.veh_id,
        idReporte: row.log_rep_id,
        tableData: this.tableData,
        fechaDesde: this.dateFrom,
        fechaHasta: this.dateTo,
        vehiculos: this.multiSelectValue,
      });
      this.$router.push({ path: "reportDetail" });
    },

    downloadDetail(row) {
        let urlxls =
            process.env.VUE_APP_API_REP_URL +
            "index.php?veh=" +
            row.veh_id +
            "&from=" +
            this.formatDate(row.log_rep_date) +
            "&to=" +
            this.formatDate(row.log_rep_date);

        urlxls += "&trip=full";
        urlxls += "&event=yes";
        urlxls += "&xval=$5INzGz|7LoUlj!)pvTO9Ur[Oz[0}";//" + process.env.VUE_APP_INDEX_KEY;

        window.open(urlxls, "_blank");
    },
    updateTable() {
      let desde = this.formatDate(this.dateFrom);
      let hasta = this.formatDate(this.dateTo);
      reporteService.resumenRecorridos(this.multiSelectValue[0].pat, desde, hasta)
        .then(res => {
          res.data.forEach(element => {
              element.log_week_day = this.showNameDay(element.log_rep_date)
          });
          this.tableData = res.data;
          this.calculateTotal()
        });
    },
    formatDate(fecha) {
      let newdate = new Date(fecha)
      return newdate.toISOString().substring(0, 10);
    },
    formatTimeToSeconds(fecha) {
        var a = fecha.split(':');
        var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
        return seconds
    },
    formatSecondsToTime(seg) {
        if (seg >= 86400) {
          var d = Math.floor(seg / 86400);
          var h = Math.floor(seg % 86400 / 3600 )
        }
        else {
            var h = Math.floor(seg / 3600 )
        }
        if (h < 10) { h = "0"+ h }
        let m = Math.floor(seg % 3600 / 60);
        let s = Math.floor(seg % 3600 % 60);
        if (m < 10) {
            m = "0"+ m
        }
        if (s < 10) { s = "0"+ s }
        if (seg >= 86400) {
            var dhms = d + 'd ' + h + ':'+ m + ':' + s
            return dhms
        }
        let hms = h + ':'+ m + ':' + s
        return hms
    },
    getVehiculos() {
      vehiculosService.index().then(res => {
        this.$store.commit("setGrupos", {
          gruposVehiculos: res.data
        });
        this.formatData();
      });
    },

    formatData() {
      var i;
      var j;
      for (i = 0; i < this.grupos.length; i++) {
        var grupo = {
          name: this.grupos[i].grupo_nombre,
          grupoVeh: []
        };
        for (j = 0; j < this.grupos[i].grupos_vehiculos.length; j++) {
          var veHSel = {
            pat: this.grupos[i].grupos_vehiculos[j].veh_id,
            key: this.grupos[i].grupos_vehiculos[j].veh_id
          };
          grupo.grupoVeh.push(veHSel);
        }
        this.vehiculos.push(grupo);
      }
      this.vehiculosDisponibles = true;
    },

    calculateTotal() {
      let vmax = 0
      let distance = 0
      let travel = 0
      let stopped = 0
      for (let i = 0; i < this.tableData.length; i++) {
        distance = distance + this.tableData[i].log_rep_distance

        travel = travel + this.formatTimeToSeconds(this.tableData[i].log_rep_trip_time)
        stopped = stopped + this.formatTimeToSeconds(this.tableData[i].log_rep_stop_time)

        if (this.tableData[i].log_rep_max_speed > vmax) {
          vmax = this.tableData[i].log_rep_max_speed
        }
      }

      travel = this.formatSecondsToTime(travel)
      stopped = this.formatSecondsToTime(stopped)

      this.total[0].vmax = vmax
      this.total[0].distance = distance
      this.total[0].travel = travel
      this.total[0].stopped = stopped
      this.totalReady = true

    },

    handleResize() {
      // let navbarHeight = document.getElementById("navbar").offsetHeight;
      let windowHeight = window.innerHeight;
      // let contentHeight = windowHeight - navbarHeight;
      let contentHeight = windowHeight;
      document.getElementById("content").style.height = contentHeight + "px";
      // document.getElementById("sidebar").style.height = windowHeight + "px";
    },

    onDialogClose() {
      this.showDialog = false;
    },
    openDialog() {
      this.$store.commit("setReporte", {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        vehiculosSeleccionados: this.multiSelectValue
      });
      this.showDialog = true;
    }
  }
};
</script>


<style>
  tbody > tr {
    cursor:pointer
   }

  .weekend-day {
    /* background: red !important; */
    color:red !important;
  }

 .no-hover:hover > td {
  /* background-color: initial !important; */
  background-color: #c32e2e !important;
}
</style>

<style scoped>
.card .card-content, .card-body{
    padding: 0px 15px 0px 15px;
    box-shadow: 0 6px 10px -4px rgba(40, 40, 40, 0.7);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
}
.form-buttons.report-buttons {
  margin: 10px 10px;
  margin-top: 40px;
}
.extended-forms {
  padding: 10px;
}
.content {
  height: 100%;
}
.etiquetas {
  text-transform: capitalize;
  font-weight: 400;
  color: #9a9a9a;
  font-size: 12px;
}

.totales {
  border: 2px solid;
  border-color: #ec3f30;
}

.modal-overlay {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color:  rgba(100, 100, 100, 0.5);
}
</style>

