<template>
  <record>

    <template v-slot:Title>
      <div class="title"><h4> {{titleView}} </h4></div>
    </template>

    <template v-slot:RecordLayout>
      <template v-if="!isViewForm">  
        <b-card>
          <b-button-toolbar class="mb-4" justify aria-label="Toolbar with button groups and input groups">
            <b-input-group>
              <!-- <b-input-group-prepend>
                <b-input-group-text class="d-none d-md-block">
                  <b-icon icon="search"></b-icon>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-input
                v-model="filter"
                type="search"
                id="filterInput"
                placeholder="Filtrar. Ingrese texto..."
              ></b-input> -->
            </b-input-group>

            <b-button-group>
              <b-button class="ml-1">
                <download-excel
                  type = "xls"
                  worksheet = "Vehiculos"
                  :name = "fullFileName"
                  :data = "items">
                  <div class="d-none d-md-block"><b-icon icon="download" class="mr-1"></b-icon>Export</div>
                  <div class="d-md-none"><b-icon icon="download"></b-icon></div>
                </download-excel>
              </b-button>
              <b-button class="ml-1" @click="onChangeView()">
                <div class="d-none d-md-block"><b-icon icon="grid-fill" class="mr-1"></b-icon>Registros</div>
                <div class="d-md-none"><b-icon icon="grid-fill"></b-icon></div>
              </b-button>
            </b-button-group>

          </b-button-toolbar>

          <!-- TODO Inicio TIMELINE para el RANGO COMPLETO... ver si es viable -->
          <div> Time Line para todo el rango de dias </div>
           <div>  {{ items}} </div>
          <!-- fin TIMELINE rango completo -->

        </b-card>
      </template>
      <template v-else>
        <b-card>

          <b-button-toolbar class="mb-4" justify key-nav>
            <b-button-group>
            </b-button-group>

            <b-button-group>
              <b-button class="mr-1 d-none d-md-block" disabled>
                {{ indexSelected + 1 }} / {{  items.length }}
              </b-button>
              <b-button class="mr-1" @click="onPrev()">
                <b-icon icon="chevron-left"></b-icon>
              </b-button>
              <b-button class="mr-1" @click="onNext()">
                <b-icon icon="chevron-right"></b-icon>
              </b-button>

              <!-- <b-button @click="onChangeView()"> -->
                <b-button @click="onChangeView()">
                <div class="d-none d-md-block"><b-icon icon="list-ul" class="mr-1"></b-icon>Lista</div>
                <div class="d-md-none"><b-icon icon="list-ul"></b-icon></div>
              </b-button>
            </b-button-group>
          </b-button-toolbar>

          <!-- TODO Inicio TIMELINE dia x dia -->
          <div> {{ indexSelected }} {{ items[indexSelected]}} </div>
        
          <!-- fin TIMELINE dia x dia -->

        </b-card>
      </template>

    </template>
  </record>
</template>

<script>
  import Record from '@/components/Record/Index';

  import dataTable from "./data"
  import resources from '@/mixins/resourcesReport'

  export default {
    name: 'Reports',
    components: {
      Record
    },
    mixins: [
      resources
    ],
    data() {
      return {
        titleModule: "Reportes",
        titleSubModule: "Linea de Tiempo",
        
        isViewForm: false,

        fileName: "Test",

        filter: null,
        headVariant: 'light',
        borderless: false,
        names: resources.names,
        fields: resources.fields,
        // items: dataTable.items,
        indexSelected: 0,
        selected: [],
        itemsResult:[],
        items: []
      }
    },
    
    computed: {
      titleView() {
        return `${this.titleModule} / ${this.titleSubModule}`
      },
      fullFileName () {
        let now = new Date();
        let nowDay = now.toISOString().slice(0,10).replace(/-/g,"");
        return `${this.fileName.trim().toLowerCase()}_${nowDay}.xls`
      },
      item() {
        if(this.indexSelected >= 0) {
          return this.items[this.indexSelected]
        } 
        return item = []
      }
    },
    mounted() {
      this.items = this.$store.state.detalleReporte.tableData
      console.log("mounted ->  this.items",  this.items)
      if (this.$route.params.indexSelected === undefined){
        this.isViewForm = !this.isViewForm
      }
      if( this.$route.params.indexSelected != -1) {
        this.isViewForm = !this.isViewForm
        this.indexSelected = this.$route.params.indexSelected
      }
    
    },
    methods: {
  
      onRowSelected(item) {
          // this.indexSelected = index
        let index = -1
        if (item.length) {
          index = this.items.findIndex(i => i.veh_id === item[0].veh_id)
        }
        this.$emit('selected', index)
        this.selected = item
      
      },
     
      onChangeView() {
        console.log('change to table/form >>>>')
        // TODO control de items lenght CERO
        this.isViewForm = !this.isViewForm
        
        if(!this.isViewForm) {
          this.indexSelected = -1
        } else if (this.indexSelected === -1) {
          this.indexSelected = this.$route.params.indexSelected
          
        }
        
      },
      onPrev() {
        // TODO control de items lenght CERO
        if(this.indexSelected > 0) {
          this.indexSelected--
        } else {
          this.indexSelected = this.items.length - 1
        }
      },
      onNext() {
        // TODO control de items lenght CERO
        if(this.indexSelected < this.items.length - 1) {
          this.indexSelected ++
        } else {
          this.indexSelected = 0
        }
      }
    }
  }
  </script>